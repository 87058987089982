.studyspot-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    overflow: hidden;
    cursor: pointer;
    
    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    &__image {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }
    
    &__card-details {
        padding: 15px;
    }

    %__label {
        margin: 0;
        font-size: 1.2em;
    }

    &__distance {
        margin: 5px 0 0;
        color: #555;
    }
}


