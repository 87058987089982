@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fff;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  z-index: 1;
}

::-webkit-scrollbar{
  z-index: 10;
  width:10px;
}

::-webkit-scrollbar-track{
  background:#e9e9e9;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #aeb4b9;
}

.all-reviews-card::-webkit-scrollbar-thumb:hover{
  background-color: #71797E;
}

